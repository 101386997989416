import { useEffect } from 'react';
/* eslint-disable no-extend-native */
import { HashRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import { createBrowserHistory } from 'history';
// import { getAccessToken } from 'services/sensediaServices';
import { theme } from 'theme';

import { AuthProvider } from 'context/Auth';
import { ContribuicaoProvider } from 'context/Contribuicao';
import { FormularioProvider } from 'context/Formulario';
import { InfoPessoaisProvider } from 'context/InfoPessoais';
import { LoginProvider } from 'context/Login';
import { ModalProvider } from 'context/Modal';
import { ModalProspectProvider } from 'context/ModalProspect';
import { PrimeiroAcessoProvider } from 'context/PrimeiroAcesso';
import { ProspectProvider } from 'context/Prospects';
import { StepsProvider } from 'context/Steps';
import { ToastProvider } from 'context/Toast';
import { TributacaoProvider } from 'context/Tributacao';

import Routes from './routes';

Date.prototype.addMinutes = function (m) {
  this.setTime(this.getTime() + m * 60 * 1000);
  return this;
};
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};
Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
Date.prototype.remDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

const history = createBrowserHistory({ basename: '/pi-flexprev' });

export default function App() {
  useEffect(() => {
    // getAccessToken();
  }, []);

  return (
    <ToastProvider>
      <ThemeProvider theme={theme}>
        <HashRouter history={history}>
          <StepsProvider>
            <AuthProvider>
              <InfoPessoaisProvider>
                <ModalProvider>
                  <LoginProvider>
                    <PrimeiroAcessoProvider>
                      <TributacaoProvider>
                        <FormularioProvider>
                          <ContribuicaoProvider>
                            <ModalProspectProvider>
                              <ProspectProvider>
                                <Routes />
                              </ProspectProvider>
                            </ModalProspectProvider>
                          </ContribuicaoProvider>
                        </FormularioProvider>
                      </TributacaoProvider>
                    </PrimeiroAcessoProvider>
                  </LoginProvider>
                </ModalProvider>
              </InfoPessoaisProvider>
            </AuthProvider>
          </StepsProvider>
        </HashRouter>
      </ThemeProvider>
    </ToastProvider>
  );
}
