import { Box, Dialog, DialogContent } from '@mui/material';

import { Button } from 'components/Button';

import { useModal } from 'context/Modal';

export function ModalErroPreCadastro() {
  const {
    handleCloseModalErroPreCadastro,
    showModalErroPreCadastro,
    erroPreCadastro,
  } = useModal();

  return (
    <Dialog
      fullWidth
      open={showModalErroPreCadastro}
      onClose={handleCloseModalErroPreCadastro}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        <Box textAlign="center">
          <img src="logo2.png" alt="logo petros" width={120} />
        </Box>
        <p style={{ margin: '1em 0' }}>{erroPreCadastro}</p>
        <Button
          normal
          sx={{ borderRadius: '20px', margin: '0 auto' }}
          onClick={() => {
            handleCloseModalErroPreCadastro();
          }}
        >
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  );
}
