import { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Dialog, DialogContent, Grid, TextField } from '@mui/material';

import { useFormik } from 'formik';
import { alterarEmail, reenviarEmail } from 'services/usuariosServices';
import * as yup from 'yup';

import { Button } from 'components/Button';

import { useModal } from 'context/Modal';
import { useToast } from 'context/Toast';

export function ModalReenvioEmail() {
  const {
    handleCloseModalReenvioEmail,
    showModalReenvioEmail,
    initialTime,
    timer,
    setTimer,
    isActive,
    setIsActive,
    idPedido,
    email,
  } = useModal();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isAlterarEmail, setAlterarEmail] = useState(false);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const resetCountdown = () => {
    setTimer(initialTime);
    setIsActive(true);
  };

  useEffect(() => {
    let countdown;

    if (isActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsActive(false);
    }

    if (timer <= 0) {
      clearInterval(countdown);
    }

    return () => clearInterval(countdown);
  }, [isActive, timer]);

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      email: yup
        .string()
        .required('E-mail é obrigatório')
        .email('E-mail inválido'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await alterarEmail(idPedido, values.email);
        setAlterarEmail(false);
        setIsActive(false);
        setTimer(0);
      } catch (error) {
        toast.error('Não foi possível alterar o e-mail');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Dialog
      fullWidth
      open={showModalReenvioEmail}
      onClose={handleCloseModalReenvioEmail}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        <Box textAlign="center">
          <img src="logo2.png" alt="logo petros" width={120} />
        </Box>
        <p style={{ textAlign: 'justify' }}>
          Ops! Parece que você inicou seu pedido, mas não confirmou seu e-mail
          ainda. Para sua segurança enviamos um e-mail de confirmação que
          precisa ser validado. Por favor, verifique sua caixa de entrada
          (incluindo Spam) ou confira se o e-mail foi digitado corretamente
          abaixo. Caso precise corrigir clique no botão Alterar e-mail e em
          seguida em Reenviar e-mail.
        </p>
        <form onSubmit={formik.handleSubmit} style={{ margin: ' 1em 0' }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                label="E-mail"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled={!isAlterarEmail}
                InputProps={{ sx: { borderRadius: '12px' } }}
              />
            </Grid>
            <Grid item>
              {!isAlterarEmail && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setAlterarEmail(true);
                  }}
                >
                  Alterar e-mail
                </Button>
              )}
              {isAlterarEmail && (
                <Button
                  variant="contained"
                  size="small"
                  disabled={Boolean(formik.errors.email) || loading}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
        <Grid container marginTop={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              normal
              onClick={handleCloseModalReenvioEmail}
              sx={{ borderRadius: '20px' }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Button
              fullWidth
              outlined
              disabled={timer > 0}
              onClick={() => {
                reenviarEmail({ email: formik.values.email });
                toast.success('E-mail enviado!');
                resetCountdown();
              }}
              sx={{
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.75)',
                },
              }}
            >
              Reenviar {timer > 0 ? formatTime(timer) : ''}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
