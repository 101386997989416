import Button from '@mui/material/Button';

import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    text-transform: none;

    ${(props) =>
      props.outlined &&
      css`
        background: #ffffff;
        border: 1px solid #00887d;
        box-sizing: border-box;
        border-radius: 20px;

        width: 250px;
        height: 40px;

        :hover {
          background: #ffffff;
        }
      `}

    ${(props) =>
      props.outlinedVinculados &&
      css`
        background: #ffffff;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 27px;

        width: 159px;
        height: 42px;

        :hover {
          background: #ffffff;
        }
      `}

    ${(props) =>
      props.normal &&
      css`
        background: #00294b;
        color: #ffffff;
        box-sizing: border-box;
        border-radius: 20px;

        width: 250px;
        height: 40px;

        :hover {
          background: #00294b;
        }
      `}

    ${(props) =>
      props.normalVinculados &&
      css`
        background: #00294b;
        color: #ffffff;
        box-sizing: border-box;
        border-radius: 20px;

        width: 159px;
        height: 42px;

        :hover {
          background: #00294b;
        }
      `}

    ${(props) =>
      props.contained &&
      css`
        background: #00887d;
        color: #ffffff;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 12px;

        width: 250px;
        height: 40px;

        :hover {
          background: #00887d;
        }
      `}

    ${(props) =>
      props.none &&
      css`
        color: #1351b4;
        font-weight: bold;
        box-sizing: border-box;
        border-radius: 12px;

        width: 250px;
        height: 40px;
      `}

    ${(props) =>
      props.icone &&
      css`
        background: #00887d;
        color: #ffffff;
        box-sizing: border-box;
        border-radius: 27px;

        width: 211px;
        height: 40px;

        :hover {
          background: #00887d;
        }
      `}
    ${(props) =>
      props.iconeOutlined &&
      css`
        background: #ffffff;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 27px;

        width: 180px;
        height: 40px;

        :hover {
          background: #ffffff;
        }
      `}
    ${(props) =>
      props.iconeOutlinedGreen &&
      css`
        background: #ffffff;
        border: 1px solid #00887d;
        box-sizing: border-box;
        border-radius: 27px;

        width: 180px;
        height: 40px;

        :hover {
          background: #ffffff;
        }
      `}

    ${(props) =>
      props.iconeDropzone &&
      css`
        background: #ffffff;
        border: 1px solid #00887d;
        box-sizing: border-box;
        border-radius: 27px;

        width: 310px;
        height: 40px;

        :hover {
          background: #ffffff;
        }
      `}
    ${(props) =>
      props.mobile &&
      css`
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 27px;

        width: 108px;
        height: 36px;

        :hover {
          background: #ffffff;
        }
      `}

    ${(props) =>
      props.senha &&
      css`
        background: #ffffff;
        border: 1px solid #00887d;
        box-sizing: border-box;
        border-radius: 27px;

        width: 309px;
        height: 48px;

        font-weight: bold;
        font-size: 16px;
        line-height: 18px;

        :hover {
          background: #ffffff;
        }
      `}

    ${(props) =>
      props.kit &&
      css`
        background: #ffffff;
        border: 1px solid #00887d;
        box-sizing: border-box;
        border-radius: 27px;

        width: 216px;
        height: 40px;

        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #4f4f4f;

        :hover {
          background: #ffffff;
        }
      `}
  }
`;
