/* eslint-disable no-unused-vars */
import { useState, createContext, useContext } from 'react';

import { subYears } from 'date-fns';
import { useFormik } from 'formik';
import { postPreCadastro } from 'services/loginServices';
import formataCpf from 'util/formatarCPF';
import * as yup from 'yup';

import { useModal } from 'context/Modal';

import { useToast } from './Toast';

const PrimeiroAcessoContext = createContext();

export function PrimeiroAcessoProvider({ children }) {
  const { toast } = useToast();
  const [isPrimeiroAcesso, setIsPrimeiroAcesso] = useState('login');
  const [acessoMobile, setAcessoMobile] = useState(false);
  const [cadastrado, setCadastrado] = useState(false);
  const { openModalAvisoEmail, openModalErroPreCadastro } = useModal();
  function isPhoneNumber(phone = '') {
    const regex = /\(\d{2}\)\d{4,5}-?\d{4}/g;
    return regex.test(phone);
  }

  yup.addMethod(yup.string, 'phone', function (errorMessage) {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test(
      'is-required-by-pessoa-exposta',
      'Digite um telefone válido (XX) XXXXX-XXXX',
      function (value) {
        const { path, createError } = this;

        return isPhoneNumber(value)
          ? true
          : createError({ path, message: errorMessage });
      }
    );
  });

  const validationSchema = yup.object({
    nome: yup
      .string()
      .required('Nome é obrigatório')
      .matches(/^([a-zA-Z\u00C0-\u00FF´']+\s)+[a-zA-Z\u00C0-\u00FF´']+$/, {
        message: 'Nome inválido',
      }),
    cpf: yup
      .string()
      .required('CPF é obrigatório')
      .transform((value) => value.replace(/\D/g, ''))
      .test({
        test: (value) => /^\d{11}$/.test(value),
        message: 'CPF inválido',
      }),
    dataNascimento: yup
      .date()
      .nullable()
      .required('Data de nascimento é obrigatório')
      .typeError('Data de nascimento inválida')
      .test({
        test: (value) => value <= subYears(new Date(), 18),
        message: 'Deve ser maior de idade',
      }),
    celular: yup
      .string()
      .required('Celular é obrigatório')
      .transform((value) => value.replace(/\D/g, ''))
      .test({
        test: (value) => /^\d{11}$/.test(value),
        message: 'Celular inválido',
      }),
    email: yup
      .string()
      .required('E-mail é obrigatório')
      .email('E-mail inválido'),
    emailConfirmacao: yup
      .string()
      .required('Confirmação de e-mail é obrigatório')
      .email('E-mail inválido')
      .oneOf([yup.ref('email')], 'Os e-mails não coincidem'),
    senha: yup
      .string()
      .required('Senha é obrigatório')
      .min(6, 'A senha precisa ter pelo menos 6 caracteres'),
    senhaConfirmacao: yup
      .string()
      .required('Confirmação de senha é obrigatório')
      .oneOf([yup.ref('senha')], 'As senhas não coincidem'),
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
      cpf: '',
      dataNascimento: null,
      celular: '',
      email: '',
      emailConfirmacao: '',
      senha: '',
      termos: false,
    },
    validationSchema,
    onSubmit: async ({
      nome,
      cpf,
      dataNascimento,
      celular,
      email,
      emailConfirmacao,
      senha,
      termos,
    }) => {
      const loading = toast.loading('Carregando...');

      try {
        if (!termos) throw new Error('Aceite os termos, para continuar.');
        if (email !== emailConfirmacao)
          throw new Error('Os emails não coincidem');
        cpf = cpf.replaceAll('.', '');
        cpf = cpf.replaceAll('-', '');

        const cpfFormatado = formataCpf(cpf);
        const cpfSemPonto = cpfFormatado.replace(/[. ]+/g, '').trim();

        const body = {
          nome,
          cpf: cpfSemPonto,
          dataNascimento,
          celular: celular.replace(/\D/g, ''),
          email,
          senha,
        };

        const { data } = await postPreCadastro(body);

        if (data.status) {
          toast.success('Cadastro realizado com sucesso!');
          setIsPrimeiroAcesso('login');
          setCadastrado(true);
          openModalAvisoEmail(email);
          formik.resetForm();
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        if (error.isAxiosError) {
          openModalErroPreCadastro(error.response.data.message);
        } else {
          toast.error(
            'Não foi possível fazer o pré-cadastro neste momento. Tente novamente mais tarde.'
          );
        }
      } finally {
        toast.dismiss(loading);
      }
    },
  });

  const trocarAcesso = (acesso) => {
    setIsPrimeiroAcesso(acesso);
  };

  const liberarAcessoMobile = () => {
    setAcessoMobile(!acessoMobile);
    trocarAcesso();
  };

  const linkPoliticaDePrivacidade = () => {
    window.open(
      'https://dpo.privacytools.com.br/policy-view/oB3DljgrO/1/poli%CC%81tica-de-privacidade/pt_BR',
      '_blank'
    );
  };

  return (
    <PrimeiroAcessoContext.Provider
      value={{
        trocarAcesso,
        isPrimeiroAcesso,
        linkPoliticaDePrivacidade,
        liberarAcessoMobile,
        acessoMobile,
        formik,
        cadastrado,
      }}
    >
      {children}
    </PrimeiroAcessoContext.Provider>
  );
}

export function usePrimeiroAcesso() {
  const context = useContext(PrimeiroAcessoContext);
  if (!context) {
    throw new Error(
      'usePrimeiroAcesso must be used within a PrimeiroAcessoProvider'
    );
  }
  return context;
}
